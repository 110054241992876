import React from 'react'
import { Box, Flex } from "@chakra-ui/react";

const Footer = () => {
  return (
    <div>
        
	{/* <!-- FOOTER TOP PART START --> */}
	<div className="footer_top" id='footer'>
		<div className="container">
			<div className="row">
				<div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-5">
					<div className="footer_widget">
						<div className="footer_widget_title">
							<h2>Contact Us!</h2>
						</div>
						<ul className="footer_widget_content">
							<li><span>Phone: &nbsp;&nbsp;&nbsp;&nbsp;</span>+91 9324416635</li>
							<li><span>Email: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>reply@actlifesciences.com</li>
							<li><span>Address: &nbsp;</span>Vashi Plaza, Sector 17, Vashi, New Mumbai - 400703</li>
						</ul>

						<div className="footer_social">
							<ul className="footer_social_icons">
								<li><a href="https://www.instagram.com/shavari_actproducts/"><i className="icofont-instagram"></i></a></li>
								<li><a href="https://www.facebook.com/shavaribar"><i className="icofont-facebook"></i></a></li>
							</ul>
						</div>
					</div>
				</div>

				{/* <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-5">
					<div className="footer_widget">
						<div className="footer_widget_title">
							<h2>Customer support</h2>
						</div>
						<ul className="footer_widget_content">
							<li><i className="icofont-double-right"></i><a href="#">about us</a></li>
							<li><i className="icofont-double-right"></i><a href="#">privacy policy</a></li>
							<li><i className="icofont-double-right"></i><a href="#">terms & conditions</a></li>
							<li><i className="icofont-double-right"></i><a href="#">product returns</a></li>
							<li><i className="icofont-double-right"></i><a href="#">wholesale policy</a></li>
						</ul>
					</div>
				</div> */}

				<div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-5">
					<div className="footer_widget">
						<div className="footer_widget_title">
							<h2>quick links</h2>
						</div>
						<ul className="footer_widget_content">
							<li><i className="icofont-double-right"></i><a href="https://amzn.eu/d/0NgaWqr">Buy on Amazon</a></li>
							<li><i className="icofont-double-right"></i><a href="https://www.actproducts.in/product/shavari//">Buy on our website</a></li>
						</ul>
					</div>
				</div>
	
			</div>
		</div>
	</div>

	{/* <!-- FOOTER TOP PART END --> */}
	{/* <!-- FOOTER BOTTOM PART START --> */}
	<div className="footer_bottom">
		<div className="container">
			<div className="row">
				<div className="col-md-12 col-sm-12 col-12">
					<div className="footer_txt text-center">
						<p>Copyright 2022 <span>ACT Products</span>. All Right Reserved</p>
					</div>
				</div>
			</div>
		</div>
	</div>

	{/* <!-- FOOTER BOTTOM PART END --> */}

    </div>
  )
}

export default Footer