import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import "./product.css";
import DetailsThumb from "./DetailsThumb";
import ProductList from "./ProductList";
import Features from "./Features";
import Reviews from "./Reviews";
import Banner from "./Banner";
import Header from "../Header";
import Footer from "../Footer";
import BuyNow from "./BuyNow";
import Faq from "./Faq";

const ProductDetails = () => {
  const [state, setState] = useState({
    products: [
      {
        _id: "1",
        title:
          "Shavari Lactation Granola Bars - Shatavari for supermoms | Natural & Preservative-Free | Supported with Research ",
        src: [
          "https://m.media-amazon.com/images/I/61XE+a7dk1L._SL1080_.jpg",
          "https://m.media-amazon.com/images/I/61kHUyqX5bL._SL1080_.jpg",
          "https://m.media-amazon.com/images/I/61NgYJxHBVL._SL1080_.jpg",
          // "https://m.media-amazon.com/images/I/61wCBZH010L._SL1280_.jpg",
          "https://m.media-amazon.com/images/I/61K+tM1SOsL._SL1080_.jpg",
          "https://m.media-amazon.com/images/I/71tnPGQGADL._SL1280_.jpg",
        ],
        description:
          "Shavari Lactation Bars increase the milk supply by 30% and are exclusively made for supermoms.",
        content1:
          "Shavari Lactation bars are granola bars (a healthy breakfast cereal) having Shatavari and oats, along with rich dry fruits, honey, and sweetened cocoa without any preservatives. It is 100% natural & contains no-preservative.",
        content2:
           "Shatavari is bitter in taste but our unique formulation makes it pleasant to taste.",
        content3:
          "Milk volume in supermoms increases by 30% compared to placebo.",
        price: "₹330",
      },
    ],
    index: 0,
  });
  let myRef = useRef();

  const handleTab = (index) => {
    setState({ ...state, index: index });
    const images = myRef.current.children;
    console.log(images);
    for (let i = 0; i < images.length; i++) {
      images[i].className = images[i].className.replace("active", "");
    }
    images[index].className = "active";
  };
  useEffect(() => {
    const { index } = state;
    console.log("use Effect index", index);
    console.log("use myRef data:", myRef.current);
    // myRef.current[index].className = "active";
  }, []);
  const { products, index } = state;
  return (
    <div>
      <Header />
      
      <br />
      <div className="app">
        {products.map((item) => (
          <div className="details" key={item._id}>
            <div className="big-img">
              <img src={item.src[index]} alt="" />
              <DetailsThumb images={item.src} tab={handleTab} myRef={myRef} />
            </div>

            <div className="box">
              <div className="row">
                <h2>{item.title}</h2>
                <span>
                  <h2>{item.price}</h2>
                </span>
              </div>
              {/* <Colors colors={item.colors} /> */}

              <p>{item.description}</p>
              <p>{item.content1}</p>
              {/* <p>{item.content2}</p>
              <p>{item.content3}</p> */}
              
              <a href="https://amzn.eu/d/0NgaWqr">
                <button style={{ margin: "4px", marginTop:"100px" }} className="cart">
                Buy On Amazon
                </button>
              </a>
              <a href="https://www.actproducts.in/product/shavari//">
                <button style={{ margin: "4px" }} className="cart">
                  Buy On Website!
                </button>
              </a>
            </div>
          </div>
        ))}
        <Features />
        <Banner />
        {/* <ProductList /> */}
        <BuyNow />
        
        <Faq />
        <Reviews />
        <div></div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetails;
