import { Box, Flex } from "@chakra-ui/react";
import React from "react";

function BuyNow() {
  return (
    <Box className="buy-now-section">
      <h1>Buy Nani ka Nuskha from website to avail 15% Discount</h1>
      <Flex className="box">
        <a href="https://amzn.to/3tS9AcR">
          <button style={{ margin: "4px" }} className="cart">
            Buy on Amazon
          </button>
        </a>
        <a href="https://www.actproducts.in/product/shavari//">
          <button style={{ margin: "4px" }} className="cart">
            Buy On Website!
          </button>
        </a>
      </Flex>
    </Box>
  );
}

export default BuyNow;
