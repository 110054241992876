import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  useDisclosure,
} from "@chakra-ui/react";

function Faq() {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  return (
    
    <Box className='buy-now-section'>
     <h1>Questions you may have</h1>
      <Accordion defaultIndex={[0]} allowToggle>
        {data.map((item, index) => (
          <AccordionItem className="accordion-wrapper" key={index}>
            <AccordionButton className="accordion-button">
              <AccordionIcon /> &nbsp;
              <Box flex="1" textAlign="left">
                {item.title}
              </Box>
            </AccordionButton>
            <AccordionPanel pb={4} className="accordion-panel">
              {item.desc}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
}

export default Faq;

const data = [
  {
    id: 1,
    title: "If I am not satisfied with your product, I can return the product and get a full refund?",
    desc: "Since its a food product there is no return policy once opened.",
  },
  {
    id: 2,
    title: "Who will be responsible if a consumer suffers from any side effects?",
    desc: " Since this is a natural product we don’t expect any side effects with same",
  },
  {
    id: 3,
    title: "Is there any discount for bulk orders?",
    desc: " Yes for 5 bars we offer 10 percent discount",
  },
];
