import React from 'react';
import { Box } from '@chakra-ui/react';
import { Image } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function Banner() {
  return (
    <Box w="100%" p={4} color="white">
      {/* <Carousel infiniteLoop showThumbs={false}>
        
       
        
    </Carousel> */}
    <Image src="./img/4-7.jpg" height="auto" width="1200px" />
    
    <Image style={{marginTop:"20px"}} src="./img/4-9.jpg" height="auto" width="1200px" />
    <Image style={{marginTop:"20px"}} src="./img/4-8.jpg" height="auto" width="1200px" />
    </Box>
  )
}