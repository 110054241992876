import React from 'react'
import ProductDetails from './productDetails/ProductDetails'

const Header = () => {
  return( <div>
        <header id="full_nav">
			
		<div className="header">
			<div className="container">
				{/* <nav className="navbar navbar-expand-lg navbar-light"> */}
					<a href="">
						<img style={{height:"66px",width:"157px", margin:"15px"}} src="img/Shavari_logo.png" alt="logo"/>
						
						 {/* <h1 className="nav-item active">Shavari</h1> */}
					</a>
					{/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<i className="icofont icofont-navigation-menu"></i>
					</button> */}

					{/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav mx-auto">
							<li className="nav-item active">
								<a className="nav-link" href="/">SHAVARI LACTATION BARS FOR SUPERMOMS</a>
							</li>

							{/* <li className="nav-item">
								<a className="nav-link" href="#full-about">About</a>
							</li>

							<li className="nav-item">
								<a className="nav-link" href="/product">Product</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="#full-testimonial">Testimonial</a>
							</li> */}

							{/* <li className="nav-item dropdown">
								<span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown">
									Pages
								</span>
								<div className="dropdown-menu" aria-labelledby="navbarDropdown">
									<a className="dropdown-item" href="productdetails.html">Product Details</a>
									<a className="dropdown-item" href="blog.html">Blog</a>
									<a className="dropdown-item" href="blogdetails.html">Blog Details</a>
									<a className="dropdown-item" href="404-error.html">404-Error</a>
									<a className="dropdown-item" href="cartoverview.html">Cartoverview</a>
									<a className="dropdown-item" href="checkout.html">Checkout</a>
								</div>
							</li> 
						</ul>

						<div className="header-content">
							{/* <div className="header_contact text-right">
								<span>Visit Us!</span>
								<span className="phone_no">+00 569 846 348</span>
							</div> 
							<div className="header_icon">
								<a href='/product'><i className="icofont icofont-cart"></i></a>
								{/* <span className="cart_no">01</span> 
							</div>
						</div>	
					</div> */}
				{/* </nav> */}
			</div>
		</div>
	</header>
    </div>
  )
}

export default Header