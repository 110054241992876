import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import { Main } from './components/Main';
import Footer from './components/Footer';
import ProductDetails from './components/productDetails/ProductDetails';
import {Routes,Route} from "react-router-dom"
import Home from './components/Home';
// import TagManager from 'react-gtm-module'

// const tagManagerArgs = {
//   gtmId: 'GTM-TBVGWL5'
// }

// TagManager.initialize(tagManagerArgs)

function App() {
  // window.dataLayer.push({
  //   event: 'pageview'
  // });
  return (
    <Routes>
      <Route path='/' element={<ProductDetails/>}/>
      {/* <Route path='/product' element={<ProductDetails/>}/> */}
    </Routes>
  );
}

export default App;
