import React from 'react'
import "./product.css"

const Reviews = () => {
  return (
    <div className="full-testimonial" id="full-testimonial">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h3 style={{textAlign:"center"}} className="section-title">What do our supermoms say?</h3>
              <p className="section-subtitle">
              Hear from mothers who use Shavari to nourish their little ones. 
              </p>
            </div>
          </div>
        
          <div className="form-row align-items-center mt-5">
      
            <div className="col-md-8 container carousel slide" id="carouselExampleControls" data-ride="carousel">
              <div className="testimonial-text row banner-slider carousel-inner">
                <div className="testimonial-info carousel-item active">
                  <p>
                    “ Shavari bar has good taste and is working well for my daughter (reducing PMS)

Doctors recommended my daughter to take shatavari powder which she did not like (hated !).

Shavari bar Working well for her for reducing pms.
Have taken 2 bars in a day for 2 months now.
                  </p>
                  <h3>Priti</h3>
                  <h4>Happy Customer</h4>
                </div>

                <div className="testimonial-info carousel-item">
                  <p>
                    “ This has really helped during my menopause period. Mood swings have decreased and it tastes good as well. Highly recommend.
                  </p>
                  <h3>vaishook</h3>
                  <h4>Happy Customer</h4>
                </div>

                {/* <div className="testimonial-info carousel-item">
                  <p>
                    “ Very effective product. Has a very soothing smell. Have been using for a month and very happy with it. Highly recommend!
                  </p>
                  <h3>Harsh Khandelwal</h3>
                  <h4>Happy Customer</h4>
                </div>
                <div className="testimonial-info carousel-item">
                  <p>
                    “ I used it at night when my kid sleeps. I spray it on the bed linen around her and she is safe from mosquitoes all night. Highly recommended.
                  </p>
                  <h3>vidushi</h3>
                  <h4>Happy Customer</h4>
                </div> */}
              </div>
                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Reviews