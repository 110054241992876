import React from 'react'
import { Box, SimpleGrid, Icon, Text, Stack, Flex } from '@chakra-ui/react';
import { TbMilk } from 'react-icons/tb';
import {GiHealthPotion,GiGoldBar} from 'react-icons/gi'

const Features = () => {
const Feature = ({ title, text, icon }) => {
    return (
      <Stack>
        <div style={{textAlign:"center"}}>
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'black'}
          rounded={'full'}
          bg={'gray.100'}
          mb={1}
          ml={"150px"}
          >
          {icon}
        </Flex>
        </div>
        <Text fontWeight={600} textAlign={"center"}>{title}</Text>
        <Text color={'gray.600'} textAlign={"center"}>{text}</Text>
      </Stack>
    );
  };
  return (
    <Box p={4} py={20} id='full-about'>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <Feature
          icon={<Icon as={TbMilk} w={10} h={10} />}
          title={'30% Increased Milk Supply'}
          text={
            'Shavari with the goodness of chocolate, honey, and Nani ka Nuskha are effective herbs for women to increase breast milk production by 30%.'
          }
        />
        <Feature
          icon={<Icon as={GiGoldBar} w={10} h={10} align={"center"}/>}
          title={'Natural and Preservative-Free'}
          text={
            'Shavari Lactation Bars are majorly based on a natural miraculous herb named Shatavari and don’t contain any preservatives. It contains micronutrients & multivitamins. '
          }
        />
        <Feature
          icon={<Icon as={GiHealthPotion} w={10} h={10} />}
          title={'Say goodbye to PMS'}
          text={
            'Shavari Lactation Bars help in reducing PMS symptoms, boost fertility anti-oxidants, burst stress, and reinforce the immune system. '
          }
        />
      </SimpleGrid>
    </Box>
  )
}

export default Features